//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ExportImportServices {
  ExportarDatos(datos) {
    return http.get("export/datosDiarios", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  ExportarDatosTodos(datos) {
    return http.get("export/datosTodos", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  ExportarProductoSucursal(datos) {
    return http.get("export/datosProductosScurusal", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  ExportarDatosInicializar(datos) {
    return http.get("export/maestros", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  ImportInicial(data) {
    return http.post("/export/importar/maestros", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push("/pages/login");
        }
      })
  }

  ImportProductoSucursal(data) {
    return http.post("/export/importar/productosSucursal", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push("/pages/login");
        }
      })
  }

  inicializarDB() {
    return http.post("/export/importar/inicializarDB" , {
      headers: { "Content-Type": "multipart/form-data" }
    })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push("/pages/login");
        }
      })
  }

  actualizarDB(data) {
    return http.post("/export/importar/actualizarDB" ,data )
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push("/pages/login");
        }
      })
  }

}


export default new ExportImportServices();